import Vue from 'vue'
import Router from 'vue-router'
import restApiService from '@/services/restApiService.js'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [{
            path: '/ecrans',
            component: () =>
                import('@/views/dashboard/Index'),
            children: [{
                    name: 'Tableau de bord',
                    path: 'tableauBord',
                    component: () =>
                        import('@/views/ecrans/TableauBord'),
                },
                {
                    name: 'Gestion des scénarios',
                    path: 'gestionScenarios',
                    component: () =>
                        import('@/views/ecrans/GestionScenarios'),
                },
                {
                    name: 'Édition scénarios',
                    path: 'editionScenario/:id',
                    component: () =>
                        import('@/views/ecrans/EditionScenario'),
                },
                {
                    name: 'Gestion des inscriptions',
                    path: 'gestionInscriptions',
                    component: () =>
                        import('@/views/ecrans/GestionInscriptions'),
                },
                {
                    name: 'Dossier de progression',
                    path: 'GestionDossierProgression',
                    component: () =>
                        import('@/views/ecrans/GestionDossierProgression'),
                },
                {
                    name: 'Progression usager',
                    path: 'progressionUsager/:id',
                    component: () =>
                        import('@/views/ecrans/ProgressionUsager'),
                },
                {
                    name: 'Les niveaux',
                    path: 'gestionNiveaux',
                    component: () =>
                        import('@/views/ecrans/GestionNiveaux'),
                },
                {
                    name: 'Les traces',
                    path: 'gestionTraces',
                    component: () =>
                        import('@/views/ecrans/GestionTraces'),
                },
                {
                    name: 'Les encarts',
                    path: 'gestionEncarts',
                    component: () =>
                        import('@/views/ecrans/GestionEncarts'),
                },
            ],
        },
        {
            path: '/pages',
            component: () =>
                import('@/views/pages/Index'),
            children: [{
                    meta: { public: true },
                    name: 'Connexion',
                    path: 'connexion',
                    component: () =>
                        import('@/views/public/Connexion'),
                },
                {
                    meta: { public: true },
                    name: 'Mot de passe',
                    path: 'reinitialiserMotDePasse',
                    component: () =>
                        import('@/views/public/RéinitialisationMotDePasse'),
                },
                {
                    name: 'Lock',
                    path: 'lock',
                    component: () =>
                        import('@/views/pages/Lock'),
                },
                {
                    name: 'Login',
                    path: 'login',
                    component: () =>
                        import('@/views/pages/Login'),
                },
                {
                    name: 'Pricing',
                    path: 'pricing',
                    component: () =>
                        import('@/views/pages/Pricing'),
                },
                {
                    name: 'Register',
                    path: 'register',
                    component: () =>
                        import('@/views/pages/Register'),
                },
            ],
        },
        {
            path: '/',
            component: () =>
                import('@/views/dashboard/Index'),
            children: [
                // Dashboard
                {
                    name: 'Gestion des scénarios',
                    path: '',
                    component: () =>
                        import('@/views/ecrans/GestionScenarios'),
                },
                // Pages
                {
                    name: 'RTL',
                    path: 'pages/rtl',
                    component: () =>
                        import('@/views/dashboard/pages/Rtl'),
                },
                {
                    name: 'User Profile',
                    path: 'pages/user',
                    component: () =>
                        import('@/views/dashboard/pages/UserProfile'),
                },
                {
                    name: 'Timeline',
                    path: 'pages/timeline',
                    component: () =>
                        import('@/views/dashboard/pages/Timeline'),
                },
                // Components
                {
                    name: 'Buttons',
                    path: 'components/buttons',
                    component: () =>
                        import('@/views/dashboard/component/Buttons'),
                },
                {
                    name: 'Grid System',
                    path: 'components/grid-system',
                    component: () =>
                        import('@/views/dashboard/component/Grid'),
                },
                {
                    name: 'Tabs',
                    path: 'components/tabs',
                    component: () =>
                        import('@/views/dashboard/component/Tabs'),
                },
                {
                    name: 'Notifications',
                    path: 'components/notifications',
                    component: () =>
                        import('@/views/dashboard/component/Notifications'),
                },
                {
                    name: 'Icons',
                    path: 'components/icons',
                    component: () =>
                        import('@/views/dashboard/component/Icons'),
                },
                {
                    name: 'Typography',
                    path: 'components/typography',
                    component: () =>
                        import('@/views/dashboard/component/Typography'),
                },
                // Forms
                {
                    name: 'Regular Forms',
                    path: 'forms/regular',
                    component: () =>
                        import('@/views/dashboard/forms/RegularForms'),
                },
                {
                    name: 'Extended Forms',
                    path: 'forms/extended',
                    component: () =>
                        import('@/views/dashboard/forms/ExtendedForms'),
                },
                {
                    name: 'Validation Forms',
                    path: 'forms/validation',
                    component: () =>
                        import('@/views/dashboard/forms/ValidationForms'),
                },
                {
                    name: 'Wizard',
                    path: 'forms/wizard',
                    component: () =>
                        import('@/views/dashboard/forms/Wizard'),
                },
                // Tables
                {
                    name: 'Regular Tables',
                    path: 'tables/regular-tables',
                    component: () =>
                        import('@/views/dashboard/tables/RegularTables'),
                },
                {
                    name: 'Extended Tables',
                    path: 'tables/extended-tables',
                    component: () =>
                        import('@/views/dashboard/tables/ExtendedTables'),
                },
                {
                    name: 'Data Tabels',
                    path: 'tables/data-tables',
                    component: () =>
                        import('@/views/dashboard/tables/DataTables'),
                },
                // Maps
                {
                    name: 'Google Maps',
                    path: 'maps/google-maps',
                    component: () =>
                        import('@/views/dashboard/maps/GoogleMaps'),
                },
                {
                    name: 'Full Screen Map',
                    path: 'maps/full-screen-map',
                    component: () =>
                        import('@/views/dashboard/maps/FullScreenMap'),
                },
                // Root level
                {
                    name: 'Widgets',
                    path: 'widgets',
                    component: () =>
                        import('@/views/dashboard/Widgets'),
                },
                {
                    name: 'Charts',
                    path: 'charts',
                    component: () =>
                        import('@/views/dashboard/Charts'),
                },
                {
                    name: 'Calendar',
                    path: 'calendar',
                    component: () =>
                        import('@/views/dashboard/Calendar'),
                },
            ],
        },
        {
            path: '*',
            component: () =>
                import('@/views/pages/Index'),
            children: [{
                name: '404 Error',
                path: '',
                component: () =>
                    import('@/views/pages/Error'),
            }],
        },
    ],
})

router.beforeEach((to, from, next) => {
    if (!to.meta.public) {
      restApiService
      .get('/api/usagers/controlerAcces')
      .then((result) => {
        next()
      })
      .catch((erreur) => {
        next({ path: '/pages/connexion' })
      })
    } else {
      next()
    }
  })
export default router
