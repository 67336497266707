import axios from 'axios'
import store from '../store'

var urlServeur = process.env.VUE_APP_SERVEUR_URL

/*
  le fichier env_public.json n'est pas nécessaire mais
  pratique pour la containerisation de l'application
*/
axios.get('env_public.json').then(({ data }) => {
  if (data && data.VUE_APP_API_BASE_URL) {
    urlServeur = data.VUE_APP_API_BASE_URL
  }
})

axios.interceptors.request.use((config) => {
  config.headers = { Authorization: `Bearer ${store.getters.jwttoken}` }
  return config
 })

const RestApiService = {

  getServeur () {
    return urlServeur
  },

  setServeur (s) {
    urlServeur = s
  },

  async get (resource) {
    const url = urlServeur + resource
    return axios.get(url).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`)
    })
  },

  async post (resource, params) {
    const url = urlServeur + resource
    return axios.post(url, params).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`)
    })
  },

  async put (resource, params) {
    const url = urlServeur + resource
    return axios.put(url, params).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`)
    })
  },
  async delete (resource) {
    const url = urlServeur + resource
    return axios.delete(url).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`)
    })
  },

  async getblob (resource) {
    const url = urlServeur + resource
    return axios({
       url: url,
       method: 'GET',
       responseType: 'blob',
      }).catch(error => {
      throw new Error(`RestApiService ${error} : ${url}`)
    })
  },

  mediaUrl (media) {
    return urlServeur + '/media/web' + media
  },
  getMedia (urlMedia) {
    if (urlMedia === null) return null
    const id = urlMedia.split('/')[1]
    return this.get('/api/media/' + id)
  },
}

export default RestApiService
