import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    jwttoken: null,
    usager: null,
  },
  plugins: [createPersistedState({
    paths: ['jwttoken', 'usager'],
  },
  )],
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },
    SET_JWTTOKEN (state, payload) {
      state.jwttoken = payload
    },
    SET_USAGER (state, payload) {
      state.usager = payload
    },

  },
  actions: {

  },
  getters: {
    jwttoken: state => { return state.jwttoken },
  },
})
